export const team_members = [
    {
        id: 0,
        pic:"https://res.cloudinary.com/dar3e312jder/image/upload/c_fill,h_500,w_500/v1735588364/website/1719791990868_gortpr.jpg",
        name: "Lois Bassey",
        position: "Founder & Technical Programs Director"
    },
    {
        id: 1,
        pic:"https://res.cloudinary.com/dar3e312jder/image/upload/v1742562077/tiku_azftmj.jpg",
        name: "Tiku Brenda Gaelle",
        position: "Program Manager"
    },
    {
        id: 2,
        pic:"https://res.cloudinary.com/dar3e312jder/image/upload/c_fill,h_500,w_500/v1734607448/chinaza_1_nrimd5.jpg",
        name: "Chinaza Stephanie Igboanugo",
        position: "Program Manager"
    },
    {
        id: 3,
        pic: "https://res.cloudinary.com/dar3e312jder/image/upload/v1742560768/rahmat_ks6es7.jpg",
        name: "Abioye Rahmat",
        position: "Product Manager"
    },
    {
        id: 4,
        pic:"https://res.cloudinary.com/dar3e312jder/image/upload/c_fill,h_500,w_500/v1734606967/martha_zmuwiu.png",
        name: "Martha Amponsah Boateng",
        position: "Associate Frontend Engineer"
    },
    {
        id: 5,
        pic:"https://res.cloudinary.com/dar3e312jder/image/upload/c_fill,h_500,w_500/v1734526277/Jane_in9ez8.jpg",
        name: "Nkwor Jane",
        position: "Associate Frontend Engineer"
    },
    {
        id: 6,
        pic:"https://res.cloudinary.com/dar3e312jder/image/upload/c_fill,h_500,w_500/v1734527181/adeola_jjhbav.jpg",
        name: "Adeola Juliet Odumeru",
        position: "People Operation Associate"
    },
    {
        id: 7,
        pic:"https://res.cloudinary.com/dar3e312jder/image/upload/c_fill,h_500,w_500/v1734526278/deborah_e6qnbr.jpg",
        name: "Deborah Njideka Simon",
        position: "Associate Digital Marketer"
    },
]